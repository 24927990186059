const { FixedNumber } = require('@ethersproject/bignumber')

class FixedNumberHelper {
  /**
   * Greater than
   */

  from(x) {
    return FixedNumber.fromString(`${x}`)
  }
  gt(first, second) {
    const diff = first.subUnsafe(second)
    return !diff.isZero() && !diff.isNegative()
  }

  subUnsafe(first, second) {
    return first.subUnsafe(second)
  }

  addUnsafe(first, second) {
    return first.addUnsafe(second)
  }

  mulUnsafe(first, second) {
    return first.mulUnsafe(second)
  }
  divUnsafe(first, second) {
    return first.divUnsafe(second)
  }

  gte(first, second) {
    const diff = first.subUnsafe(second)
    return !diff.isNegative()
  }

  diff(first, second) {
    const diff = first.subUnsafe(second)
    return !diff.isZero()
  }

  /**
   * Less than
   */
  lt(first, second) {
    return first.subUnsafe(second).isNegative()
  }

  lte(first, second) {
    const diff = first.subUnsafe(second)
    return diff.isZero() || diff.isNegative()
  }

  fromWei(amount, decimals = 18) {
    return FixedNumber.fromString(`${amount}`).divUnsafe(FixedNumber.fromString(`${10 ** decimals}`))
  }
  toWei(amount, decimals = 18) {
    const amountInWei = amount
      .mulUnsafe(FixedNumber.fromString(`${10 ** decimals}`))
      .toFormat({ decimals: 0 })
      .toString()
    return amountInWei
  }
}

export default new FixedNumberHelper()
