import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import auth from './auth'
import lockTokens from './lockTokens'
import tokenLocks from './tokenLocks'
import bulkSendPrepare from './bulkSendPrepare'
import bulkSendConfirm from './bulkSendConfirm'
import bulkSendSending from './bulkSendSending'
import claimAddWallet from './claimAddWallet'
import claimDetailByOwner from './claimDetailByOwner'
import claimDetailByUser from './claimDetailByUser'
import claimAddWalletSending from './claimAddWalletSending'
import claimAddWalletConfirm from './claimAddWalletConfirm'
import claimPools from './claimPools'
Vue.use(Vuex)
import _ from 'lodash'
export default createStore(Vuex.Store, {
  state: {},
  modules: {
    auth,
    lockTokens,
    tokenLocks,
    bulkSendPrepare,
    bulkSendConfirm,
    bulkSendSending,
    claimAddWallet,
    claimPools,
    claimAddWalletConfirm,
    claimAddWalletSending,
    claimDetailByOwner,
    claimDetailByUser,
  },
  mutations: {},
  actions: {},
  mixins: {
    mutations: {
      changeState: function (state, changed) {
        Object.entries(changed).forEach(([name, value]) => {
          state[name] = value
        })
      },
      changeDeepState: function (state, changed) {
        Object.entries(changed).forEach(([firstChildName, diff]) => {
          const firstChildValue = { ...state[firstChildName] }
          Object.entries(diff).forEach(([path, diffValue]) => {
            _.setWith(firstChildValue, path, diffValue)
          })
          state[firstChildName] = firstChildValue
        })
      },
    },
  },
})
