const routes = [
  { path: '/', redirect: 'home' },
  {
    path: '/',
    component: () => import('@/components/layouts/MainLayout'),
    meta: {
      title: 'Trang chủ',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomePage.vue'),
        meta: {
          title: 'home',
        },
      },
      { path: 'bulkSender', redirect: 'bulkSender/prepare' },
      {
        path: 'bulkSender/connectWallet',
        name: 'connectWalletBulkSend',
        component: () => import('@/views/bulksend/ConnectWalletBulkSend.vue'),
        meta: {
          title: 'bulkSend',
        },
      },
      {
        path: 'bulkSender/prepare',
        name: 'prepare',
        component: () => import('@/views/bulksend/BulkSendPrepare.vue'),
        meta: {
          title: 'bulkSend',
        },
      },
      {
        path: 'bulkSender/confirm',
        name: 'confirm',
        component: () => import('@/views/bulksend/BulkSendConfirm.vue'),
        meta: {
          title: 'bulkSend',
        },
      },
      {
        path: 'bulkSender/sending',
        name: 'sending',
        component: () => import('@/views/bulksend/BulkSendSending.vue'),
        meta: {
          title: 'bulkSend',
        },
      },
      { path: 'locker', redirect: 'locker/selectLockToken' },
      {
        path: 'locker/connectWallet',
        name: 'locker/connectWallet',
        component: () => import('@/views/locker/ConnectWalletLocker.vue'),
        meta: {
          title: 'locker',
        },
      },
      {
        path: 'locker/selectLockToken',
        name: 'selectLockToken',
        component: () => import('@/views/locker/SelectLockToken.vue'),
        meta: {
          title: 'locker',
        },
      },
      {
        path: 'locker/lockTokens/:tokenAddr',
        name: 'lockTokens',
        component: () => import('@/views/locker/LockTokens.vue'),
        meta: {
          title: 'locker',
        },
      },
      {
        path: 'locker/tokenLocks/:tokenAddr',
        name: 'tokenLocks',
        component: () => import('@/views/locker/TokenLocks.vue'),
        meta: {
          title: 'locker',
        },
      },
      { path: 'claimer', redirect: 'claimer/pools' },
      {
        path: 'claimer/connectWallet',
        name: 'ConnectWalletClaimer',
        component: () => import('@/views/claimer/ConnectWalletClaimer.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/pools',
        name: 'ClaimPools',
        component: () => import('@/views/claimer/ClaimPools.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/owner/:id?/addWallet/step=1',
        name: 'claimAddWallet',
        component: () => import('@/views/claimer/ClaimAddWallet.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/owner/:id?/addWallet/step=2',
        name: 'ClaimAddWalletConfirm',
        component: () => import('@/views/claimer/ClaimAddWalletConfirm.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/owner/:id?/addWallet/step=3',
        name: 'ClaimAddWalletSending',
        component: () => import('@/views/claimer/ClaimAddWalletSending.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/user/:id',
        name: 'ClaimDetailByUser',
        component: () => import('@/views/claimer/ClaimDetailByUser.vue'),
        meta: {
          title: 'claimer',
        },
      },
      {
        path: 'claimer/owner/:id',
        name: 'ClaimDetailByOwner',
        component: () => import('@/views/claimer/ClaimDetailByOwner.vue'),
        meta: {
          title: 'claimer',
        },
      },
    ],
  },
]

export default routes
