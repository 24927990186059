import { web3 } from '@/helpers/LockHandler.js'
import { isEmpty, map, chunk, groupBy } from 'lodash'
import 'codemirror/addon/display/placeholder.js'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import { FIXEDNUMBER_0, MAX_ACCOUNT_PER_TRANS } from '@/constants/index'
import alert from '@/plugins/alert'

export default {
  namespaced: true,
  state: {
    errorList: {},
    duplicateList: {},
    editorData: [],
    isEmptyError: false,
    editor: {},
    nextLoading: false,
    combineLoading: false,
    deleteLoading: false,
    transNumber: 0,
    totalTokens: FIXEDNUMBER_0,
    isVip: false,
    txFee: 0,
    estimateGas: FIXEDNUMBER_0,
    codeMirrorText: '',
  },
  actions: {
    changeEmptyError({ commit }, state) {
      commit('changeState', {
        isEmptyError: state,
      })
    },
    async handleEstimateGas({ state }, { id, decimals }) {
      const arr = chunk(state.editorData, MAX_ACCOUNT_PER_TRANS)
      try {
        let promises = []
        arr.map(async (item) => {
          const addresses = map(item, 'address')
          const values = map(item, 'amount')
          promises.push(state.multiClaimHandler.estimateGasAddWinnerList(id, addresses, values, decimals))
        })
        const estimates = await Promise.all(promises)
        const totalEstimates = estimates.reduce((a, b) => {
          return fixedNumberHelper.addUnsafe(a, b)
        }, FIXEDNUMBER_0)

        return totalEstimates
      } catch (e) {
        console.error(e)
      }
    },
    async deleteLines({ state, dispatch, commit }) {
      commit('changeState', { deleteLoading: true })
      dispatch('validateData')
      const finalData = state.editorData.filter((item) => !item.isInvalidParam)
      const convertedString = await dispatch('convertArrayToString', finalData)
      state.editor.setValue(convertedString)
      dispatch('validateData')
      commit('changeState', { deleteLoading: false })
    },
    async handleCombine({ state, dispatch, commit }) {
      commit('changeState', { combineLoading: true })
      dispatch('validateData')
      const validData = state.editorData.filter((item) => !item.isInvalidParam)
      const group = groupBy(validData, 'address')
      let tmp = {}
      Object.keys(group).map((key) => {
        if (group[key].length > 1) {
          tmp[key] = group[key].reduce((a, b) => {
            if (a.isZero()) return b.amount
            else return fixedNumberHelper.addUnsafe(a, b.amount)
          }, FIXEDNUMBER_0)
        }
      })
      const duplicateAddressArr = Object.keys(tmp)
      const filterCombinedData = state.editorData.filter((item, index) => {
        if (item.isInvalidParam || !duplicateAddressArr.includes(item.address)) return true
        const firstIndex = state.editorData.findIndex((f) => f.address === item.address)
        if (firstIndex === index) return true
      })
      const finalData = filterCombinedData.map((item) => {
        if (duplicateAddressArr.includes(item.address) && !item.isInvalidParam) item.amount = tmp[item.address]
        return item
      })
      const convertedString = await dispatch('convertArrayToString', finalData)
      state.editor.setValue(convertedString)
      dispatch('validateData')
      commit('changeState', { combineLoading: false })
    },
    // eslint-disable-next-line no-empty-pattern
    convertArrayToString({}, array) {
      let s = ''
      array.map((item, index) => {
        const breakLine = array.length - 1 === index ? '' : '\n'
        if (item.isInvalidParam) s = s + `${item.text}${breakLine}`
        else s = s + `${item.address},${item.amount}${breakLine}`
      })
      return s
    },
    async fetchData({ commit }, { editor, multiClaimHandler }) {
      try {
        commit('changeState', { editor, multiClaimHandler })
        const isVip = await multiClaimHandler.isVIP()
        const txFee = await multiClaimHandler.txFee()
        commit('setInitData', { editor, multiClaimHandler, isVip, txFee: txFee._value })
      } catch (e) {
        console.error(e)
        alert('Get fee failed')
      }
    },
    async nextStep({ state, getters, commit, dispatch }, { id, decimals }) {
      try {
        commit('changeState', { nextLoading: true })
        dispatch('validateData')
        if (getters.errorCount === 0 && getters.duplicateCount === 0) {
          if (state.editorData.length === 0) {
            commit('changeState', { isEmptyError: true })
            return false
          } else {
            const transNumber = Math.ceil(parseFloat(`${state.editorData.length}`) / MAX_ACCOUNT_PER_TRANS)
            const estimateGas = await dispatch('handleEstimateGas', { id, decimals })
            const totalTokens = state.editorData.reduce((a, b) => {
              if (a.isZero()) return b.amount
              else return fixedNumberHelper.addUnsafe(a, b.amount)
            }, FIXEDNUMBER_0)
            commit('changeState', {
              transNumber,
              totalTokens,
              estimateGas,
              codeMirrorText: state.editor.getValue(),
            })
            return true
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        commit('changeState', { nextLoading: false })
      }
    },
    validateData({ commit, state }) {
      let editorData = []
      let errorList = {}
      let duplicateList = {}
      let i = 0
      let line = 1
      //handle invalid address and wrong amount
      state.editor.eachLine((f) => {
        state.editor.removeLineClass(line - 1, 'wrap', 'line-error')
        if (isEmpty(f.text)) {
          line++
          return
        }
        let errorMsg = ''
        let arr = f.text.trim().split(',')
        arr = arr.map((item) => item.trim())
        if (arr.length < 2 || !new RegExp(/^\d+(\.\d*)?$/).test(arr[1])) {
          errorMsg = web3.utils.isAddress(arr[0])
            ? 'wrong amount'
            : `${arr[0]} is a invalid wallet address and wrong amount`
        } else if (!web3.utils.isAddress(arr[0])) errorMsg = `${arr[0]} is a invalid wallet address`
        if (!isEmpty(errorMsg)) {
          errorList[line] = errorMsg
          editorData[i] = { text: f.text, isInvalidParam: true }
        } else {
          editorData[i] = {
            text: f.text,
            isInvalidParam: false,
            address: arr[0],
            amount: fixedNumberHelper.from(arr[1]),
            line: line,
          }
        }
        i++
        line++
      })
      const validData = editorData.filter((item) => !item.isInvalidParam)
      const groupByAddress = Object.values(groupBy(validData, 'address'))
      groupByAddress.map((group) => {
        if (group.length > 1) {
          group.map((item, index) => {
            if (index > 0) duplicateList[item.line] = `duplicate address ${item.address}`
          })
        }
      })
      Object.keys(errorList).map((key) => {
        state.editor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
      })
      Object.keys(duplicateList).map((key) => {
        state.editor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
      })
      commit('setData', [errorList, duplicateList, editorData])
    },
    resetData({ commit }) {
      commit('resetData')
    },
  },
  mutations: {
    setData(state, [errorList, duplicateList, editorData]) {
      state.errorList = errorList
      state.duplicateList = duplicateList
      state.editorData = editorData
    },
    setInitData(state, { isVip, txFee, editor, multiClaimHandler }) {
      state.editor = editor
      state.multiClaimHandler = multiClaimHandler
      state.isVip = isVip
      state.txFee = txFee
    },
    resetData(state) {
      state.codeMirrorText = ''
      state.transNumber = 0
      state.totalTokens = FIXEDNUMBER_0
    },
  },
  getters: {
    errorCount: (state) => {
      return Object.keys(state.errorList).length
    },
    duplicateCount: (state) => {
      return Object.keys(state.duplicateList).length
    },
  },
}
