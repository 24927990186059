const Web3 = require('web3')
import { uniq } from 'lodash'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import { ADDRESS_0 } from '@/constants/index'
export const web3 = new Web3(window.ethereum)

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve())
      .on('error', (error) => reject(error))
  })
}

export default class LockHandler {
  bscLockContract
  contractAddress
  constructor(account, mainnet = true) {
    this.account = account
    this.contractAddress = mainnet
      ? process.env.VUE_APP_LOCK_CONTRACT_ADDRESS_MAIN_NET
      : process.env.VUE_APP_LOCK_CONTRACT_ADDRESS_TEST_NET
    this.bscLockContract = new web3.eth.Contract(require('./LockContract.abi.json'), this.contractAddress)
  }

  async approvedContract(selectedTokenContract) {
    try {
      const allowance = await selectedTokenContract.methods
        .allowance(
          this.account,
          this.mainnet
            ? process.env.VUE_APP_LOCK_CONTRACT_ADDRESS_MAIN_NET
            : process.env.VUE_APP_LOCK_CONTRACT_ADDRESS_TEST_NET
        )
        .call()
      return !!+web3.utils.fromWei(allowance)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getUserLockedTokensInfo() {
    let lockedTokenPromises = []
    const LockedTokensLength = await this.bscLockContract.methods.getUserLockedTokensLength(this.account).call()

    for (var i = 0; i < LockedTokensLength; i++) {
      lockedTokenPromises.push(this.bscLockContract.methods.getUserLockedTokenAtIndex(this.account, i).call())
    }

    const lockedTokens = await Promise.all(lockedTokenPromises)
    const uniqueLockedTokens = uniq(lockedTokens)
    const targets = []
    await Promise.all(
      uniqueLockedTokens.map(async (token) => {
        const lokerAmount = await this.bscLockContract.methods.getUserLocksForTokenLength(this.account, token).call()
        const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), token)
        const [name, decimals, symbol, balance] = await Promise.all([
          tokenContract.methods.symbol().call(),
          tokenContract.methods.decimals().call(),
          tokenContract.methods.name().call(),
          tokenContract.methods.balanceOf(this.account).call(),
        ])
        const balancefromWei = fixedNumberHelper.fromWei(balance, decimals)
        targets.push({ lockerAmount: lokerAmount, token, decimals, name, symbol, balance: balancefromWei })
      })
    )
    return targets
  }

  async getTokenInfoByAddress(token) {
    const lokerAmount = await this.bscLockContract.methods.getUserLocksForTokenLength(this.account, token).call()
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), token)
    const [name, decimals, symbol, balance, totalSupply] = await Promise.all([
      tokenContract.methods.name().call(),
      tokenContract.methods.decimals().call(),
      tokenContract.methods.symbol().call(),
      tokenContract.methods.balanceOf(this.account).call(),
      tokenContract.methods.totalSupply().call(),
    ])

    const balancefromWei = fixedNumberHelper.fromWei(balance, decimals)

    return {
      lockerAmount: lokerAmount,
      decimals,
      name,
      symbol,
      balance: balancefromWei,
      tokenAddress: token,
      totalSupply,
    }
  }

  async getWithdrawableTokens(lockId, decimals) {
    const amountTokens = await this.bscLockContract.methods.getWithdrawableTokens(lockId).call()
    return fixedNumberHelper.fromWei(amountTokens, decimals)
  }

  async handleWithdraw(lockId, amount, decimals) {
    const f = this.bscLockContract.methods.withdraw(lockId, fixedNumberHelper.toWei(amount, decimals))
    await sendRequest(f, this.account)
  }

  async handleLock(userTokenInfo, lockParams, remainPercent) {
    const cloneParams = JSON.parse(JSON.stringify(lockParams))
    const standardLockParams = cloneParams.map((lock) => {
      if (lock.condition !== ADDRESS_0) throw "Condition Address isn't accepted"
      const amount = fixedNumberHelper.from(lock.amount._value)
      lock.amount = fixedNumberHelper.divUnsafe(amount, remainPercent)
      return lock
    })
    const f = this.bscLockContract.methods.lock(userTokenInfo.tokenAddress, standardLockParams)
    await sendRequest(f, this.account)
  }
  async handleRelock(lockId, unlockDate) {
    const f = this.bscLockContract.methods.relock(lockId, unlockDate)
    await sendRequest(f, this.account)
  }

  async getUsertokenLocks(token, decimals) {
    let tokenLockPromises = []
    const tokenLocksTotal = await this.bscLockContract.methods.getUserLocksForTokenLength(this.account, token).call()
    for (var i = 0; i < tokenLocksTotal; i++) {
      tokenLockPromises.push(this.getUserLockPromise(token, i, decimals))
    }
    const tokenLocks = await Promise.all(tokenLockPromises)
    return tokenLocks
  }

  async getUserLockPromise(token, i, decimals) {
    const lockId = await this.bscLockContract.methods.getUserLockIDForTokenAtIndex(this.account, token, i).call()
    return await this.getLock(lockId, decimals)
  }

  async getLock(lockId, decimals) {
    const tokenLock = await this.bscLockContract.methods.getLock(lockId).call()
    //type specify which state lock is
    //type = 0 -> locked, type =1 => unloked, type = 2 -> withdrawn. default type  is 0
    return {
      lockId: tokenLock[0],
      tokenAddress: tokenLock[1],
      tokensDeposited: fixedNumberHelper.fromWei(tokenLock[2], decimals),
      tokensWithdrawn: fixedNumberHelper.fromWei(tokenLock[3], decimals),
      startEmission: tokenLock[6],
      endEmission: tokenLock[7],
      owner: tokenLock[8],
      lockCreator: tokenLock[9],
      condition: tokenLock[10],
    }
  }

  async getTokenLocks(token, decimals) {
    let tokenLockPromises = []
    let tokenLocks = []
    const numTokenLocks = await this.bscLockContract.methods.getTokenLocksLength(token).call()
    for (var i = 0; i < numTokenLocks; i++) {
      tokenLockPromises.push(this.getLockPromise(token, i, decimals))
    }
    tokenLocks = await Promise.all(tokenLockPromises)
    return tokenLocks
  }
  async getLockPromise(token, i, decimals) {
    const tokenLockID = await this.bscLockContract.methods.getTokenLockIDAtIndex(token, i).call()
    return await this.getLock(tokenLockID, decimals)
  }

  async getTokenFee() {
    const lockFee = await this.bscLockContract.methods.getTokenFee().call()
    return lockFee
  }
}
