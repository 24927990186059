import { defaultTokenSymbol } from '@/helpers/MultiSendHandler.js'
export default {
  namespaced: true,
  state: {
    approving: false,
    isApproved: false,
    fetching: true,
  },
  actions: {
    async onApproveContract({ commit }, [multiSendHandler, selectedToken]) {
      try {
        commit('changeState', { approving: true })
        await multiSendHandler.approveContract(selectedToken)
        const isApproved = await multiSendHandler.approvedContract(selectedToken)
        commit('changeState', { isApproved })
      } catch (e) {
        console.error(e)
      } finally {
        commit('changeState', { approving: false })
      }
    },
    async fetchData({ commit }, [multiSendHandler, selectedToken]) {
      commit('resetData')
      const isApproved =
        selectedToken.symbol === defaultTokenSymbol ? true : await multiSendHandler.approvedContract(selectedToken)
      commit('changeState', { isApproved, fetching: false })
    },
  },
  mutations: {
    resetData(state) {
      state.approving = false
      state.isApproved = false
      state.fetching = true
    },
  },
  getters: {},
}
