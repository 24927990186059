import alert from '@/plugins/alert'
import detectEthereumProvider from '@metamask/detect-provider'
import LockHandler from '@/helpers/LockHandler.js'
import MultiSendHandler, { defaultTokenSymbol } from '@/helpers/MultiSendHandler.js'
import MultiClaimHandler from '@/helpers/MultiClaimHandler.js'
import router from '@/router'
import { web3 } from '@/helpers/LockHandler.js'
import { FixedNumber } from 'ethers'

let providerListener = false

export default {
  namespaced: true,
  state: {
    connectingWallet: false,
    account: '',
    provider: '',
    loaded: false,
    lockHandler: null,
    multiSendHandler: null,
    selectedToken: null,
    chainId: '',
    bnbBalance: FixedNumber.from('0'),
  },
  actions: {
    async init({ commit, dispatch }) {
      try {
        const provider = await detectEthereumProvider()
        const chainId = await provider.request({ method: 'eth_chainId' })
        commit('changeState', { provider, chainId })
        const accounts = await window.ethereum.request({ method: 'eth_accounts' })
        if (accounts.length) {
          await dispatch('connectMetamask')
        }
      } catch (err) {
        console.error(err)
      } finally {
        commit('changeState', { loaded: true })
      }
    },
    async connectMetamask({ commit, state }) {
      try {
        commit('changeState', { connectingWallet: true })
        const accounts = await state.provider.request({
          method: 'eth_requestAccounts',
        })
        if (accounts.length) {
          if (!providerListener) {
            providerListener = true
            state.provider.on('chainChanged', (chainId) => {
              if (+chainId !== 56 && +chainId !== 97) {
                alert.error('Wrong etherum network')
              }
              const path = router.history.current.fullPath.substring(0)
              const pathArr = path.split('/')
              router.push(`/${pathArr[0] || ''}`)
              window.location.reload()
            })
            state.provider.on('accountsChanged', function () {
              const path = router.history.current.fullPath.substring(0)
              const pathArr = path.split('/')
              router.push(`/${pathArr[0] || ''}`)
              window.location.reload()
            })
          }
          const account = accounts[0].toLowerCase()
          const lockHandler = new LockHandler(account, +state.chainId === 56)
          const multiSendHandler = new MultiSendHandler(account, +state.chainId === 56)
          const multiClaimHandler = new MultiClaimHandler(account, +state.chainId === 56)
          const bnbBalance = await web3.eth.getBalance(account)
          const bnbInfo = {
            decimals: 18,
            name: 'BNB',
            symbol: defaultTokenSymbol,
            balance: FixedNumber.from(web3.utils.fromWei(bnbBalance)),
          }
          commit('changeState', {
            lockHandler,
            account,
            multiSendHandler,
            multiClaimHandler,
            bnbInfo,
          })
        }
        commit('changeState', { connectingWallet: false })
      } catch (e) {
        console.error(e)
        commit('changeState', { connectingWallet: false })
        throw e
      }
    },
  },

  mutations: {},
  getters: {
    isCorrectNetWork: (state) => {
      return +state.chainId === 56 || +state.chainId === 97
    },
  },
}
