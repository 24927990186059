/* eslint-disable no-unused-vars */
import moment from 'moment'
import { FIXEDNUMBER_0 } from '@/constants/index'
const { FixedNumber } = require('@ethersproject/bignumber')
export default {
  namespaced: true,
  state: {
    id: '',
    amount: FIXEDNUMBER_0,
    token: '',
    activeDate: '',
    createdDate: '',
    description: '',
    name: '',
    winnerList: [],
    claimedList: [],
    amountList: [],
    searchText: '',
    paused: false,
    closed: false,
    loading: false,
  },
  actions: {
    async fetchClaimDetail({ commit }, { multiClaimHandler, id, router, account }) {
      try {
        commit('changeState', { loading: true })
        const { claimInfo, winnerInfo } = await multiClaimHandler.getClaimInfo(id)
        if (claimInfo.owner.toLowerCase() !== account) {
          router.replace('/claimer/pools')
          return
        }
        if (claimInfo.closed) {
          router.replace('/claimer')
          return
        }
        commit('setInitData', { claimInfo, winnerInfo })
      } catch (e) {
        console.error('error fetchClaimDetail: ', e.message)
        router.replace('/claimer/pools')
      } finally {
        commit('changeState', { loading: false })
      }
    },
    searchInputChange({ commit }, value) {
      commit('changeState', { searchText: value.trim().toLowerCase() })
    },
  },
  mutations: {
    setInitData(state, { claimInfo, winnerInfo }) {
      state.id = claimInfo.id
      state.amount = claimInfo.amount
      state.token = claimInfo.token
      state.activeDate = claimInfo.activeDate
      state.createdDate = claimInfo.createdDate
      state.description = claimInfo.description
      state.name = claimInfo.name
      state.tokenAddress = claimInfo.tokenAddress
      state.winnerList = winnerInfo.winnerList
      state.claimedList = winnerInfo.claimedList
      state.amountList = winnerInfo.amountList
      state.paused = claimInfo.paused
      state.closed = claimInfo.closed
      state.loading = false
    },
    updateAmountList(state, { index, amount }) {
      const amountList = JSON.parse(JSON.stringify(state.amountList))
      let sum = state.amount
      sum = sum.addUnsafe(FixedNumber.from(amount)).subUnsafe(FixedNumber.from(amountList[index]))
      amountList[index] = amount
      state.amountList = amountList
      state.amount = sum
    },
    addWallet(state, { address, amount }) {
      const amountList = JSON.parse(JSON.stringify(state.amountList))
      const winnerList = JSON.parse(JSON.stringify(state.winnerList))
      const index = winnerList.indexOf(address)
      let sum = state.amount
      if (index >= 0) {
        sum = sum.addUnsafe(FixedNumber.from(amount)).subUnsafe(FixedNumber.from(amountList[index]))
        amountList[index] = amount
      } else {
        winnerList.push(address)
        amountList.push(amount)
        state.winnerList = winnerList
        sum = sum.addUnsafe(FixedNumber.from(amount))
      }
      state.amount = sum
      state.amountList = amountList
    },
  },
  getters: {
    beforeActiveDate: (state) => {
      if (!state.activeDate) return false
      return moment(state.activeDate * 1000).isAfter(moment())
    },
    completedCount: (state) => {
      let count = 0
      for (let i = 0; i < state.winnerList.length; i++) {
        if (state.claimedList[i] || state.amountList[i] === 0) count++
      }
      return count
    },
    isCompleted: (state, getters) => {
      if (!state.activeDate) return false
      if (!getters.beforeActiveDate) {
        return getters.completedCount === state.winnerList.length
      }
      return false
    },
    claimInfo: (state) => {
      const { amount, token, activeDate, description, name, tokenAddress, createdDate } = state
      return { amount, token, activeDate, description, name, tokenAddress, createdDate }
    },
    winnerInfo: (state) => {
      const winnerInfo = []
      const { winnerList, claimedList, amountList } = state
      for (let i = 0; i < winnerList.length; i++) {
        winnerInfo.push({
          index: i,
          address: winnerList[i],
          claimed: claimedList[i],
          amount: amountList[i],
        })
      }
      if (state.searchText) {
        return winnerInfo.filter((item) => item.address.toLowerCase().includes(state.searchText))
      }
      return winnerInfo
    },
    claimedNumber: (state, getters) => {
      if (getters.beforeActiveDate) return 0
      let count = 0
      const { winnerList, claimedList, amountList } = state
      for (let i = 0; i < winnerList.length; i++) {
        if (claimedList[i] || amountList[i] == 0) count++
      }
      return count
    },
  },
}
