import XLSX from 'xlsx'
import { isEmpty } from 'lodash'
export default {
  csvJSON(csv, headers = ['address', 'amount'], defaultValue = '0') {
    var lines = csv.split('\n')
    var result = []
    for (var i = 1; i < lines.length; i++) {
      if (isEmpty(lines[i])) continue
      var obj = {}
      var currentline = lines[i].trim().split(',')
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j] || defaultValue
      }
      result.push(obj)
    }
    return result
  },
  xlsxToJson(xlsx, headers = { A: 'address', B: 'amount' }) {
    const workbook = XLSX.read(xlsx, { type: 'binary' })
    var sheet_name_list = workbook.SheetNames
    let res = []
    sheet_name_list.forEach(function (y) {
      var worksheet = workbook.Sheets[y]
      var data = []
      for (let z in worksheet) {
        if (z[0] === '!') continue
        //parse out the column, row, and value
        var col = z.substring(0, 1)
        var row = parseInt(z.substring(1))
        var value = worksheet[z].v
        if (row == 1) continue
        if (!data[row]) data[row] = {}
        data[row][headers[col]] = value || ''
      }
      //drop those first two rows which are empty
      data.shift()
      data.shift()
      res = [...res, ...data]
    })
    return res
  },
}
