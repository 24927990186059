import { chunk, map } from 'lodash'
import { MAX_ACCOUNT_PER_TRANS } from '@/constants/index'
export default {
  namespaced: true,
  state: {
    data: [],
  },
  actions: {
    onMouseEnter({ commit, state }, index) {
      const data = state.data.map((item, itemIndex) => {
        if (itemIndex === index) item.displayCopyIcon = true
        return item
      })
      commit('changeState', { data })
    },
    onMouseLeave({ commit, state }, index) {
      const data = state.data.map((item, itemIndex) => {
        if (itemIndex === index) item.displayCopyIcon = false
        return item
      })
      commit('changeState', { data })
    },
    async handleSending({ commit, state, dispatch }, { id, multiClaimHandler, editorData, decimals }) {
      commit('changeState', { data: [] })

      const arr = chunk(editorData, MAX_ACCOUNT_PER_TRANS)
      try {
        arr.map(async (item, index) => {
          const transactionInfo = await dispatch('convertArrayToString', item)
          const callback = function ({ completed }) {
            const trans = state.data[index]
            if (trans) {
              trans.loading = false
              trans.state = completed
            } else {
              const data = [...state.data, { loading: false, state: false, transactionInfo, displayCopyIcon: false }]
              commit('changeState', { data })
            }
          }
          const addresses = map(item, 'address')
          const values = map(item, 'amount')
          const res = await multiClaimHandler.addWinnerList(id, addresses, values, decimals, callback)
          const data = [
            ...state.data,
            { hash: res, loading: true, state: false, transactionInfo, displayCopyIcon: false },
          ]
          commit('changeState', { data })
        })
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line no-empty-pattern
    convertArrayToString({}, array) {
      let s = ''
      array.map((item, index) => {
        const breakLine = array.length - 1 === index ? '' : '\n'
        if (item.isInvalidParam) s = s + `${item.text}${breakLine}`
        else s = s + `${item.address},${item.amount}${breakLine}`
      })
      return s
    },
  },
  mutations: {},
  getters: {},
}
