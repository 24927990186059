import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
const bulkSenderPrevent = ['bulksender/confirm', 'bulksender/sending']
router.beforeEach((to, from, next) => {
  let unwatch
  unwatch = store.watch(
    (state) => state.auth.loaded,
    (loaded) => {
      if (loaded) {
        const connected = store.state.auth.account
        const path = to.path.toLocaleLowerCase().substring(1)
        const pathArr = path.split('/')
        const rootPath = pathArr[0]
        const childPath = pathArr[1]
        if (rootPath === 'bulksender') {
          if (childPath === 'connectwallet') {
            if (!connected) next()
            else next('/bulksender/prepare')
          } else {
            if (!connected) next('/bulksender/connectWallet')
            else if (bulkSenderPrevent.includes(path) && !from.name) next('/bulksender/prepare')
            else next()
          }
        } else if (rootPath === 'locker') {
          if (childPath === 'connectwallet') {
            if (!connected) next()
            else next('/locker/selectLockToken')
          } else {
            if (!connected) next('/locker/connectWallet')
            else next()
          }
        } else if (rootPath === 'claimer') {
          if (childPath === 'connectwallet') {
            if (!connected) next()
            else next('/claimer/pools')
          } else if (childPath === 'owner') {
            if (!connected) next('/claimer/connectwallet')
            else if (pathArr.length === 5) {
              const idParam = pathArr[2]
              if (!from.name) next(`/claimer/owner/${idParam}`)
              else next()
            } else next()
          } else {
            if (!connected) next('/claimer/connectwallet')
            else next()
          }
        } else next()
        setTimeout(() => unwatch(), 1)
      }
    },
    { immediate: true }
  )
})

export default router
