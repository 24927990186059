// import alert from '@/plugins/alert'

import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
// import addLock from './addLock'
import { web3 } from '@/helpers/LockHandler.js'

import { MIN_DEPOSIT_TOKEN, FIXEDNUMBER_100, ADDRESS_0, FIXEDNUMBER_0 } from '@/constants/index'

export default {
  namespaced: true,
  state: {
    selectedTokenContract: null,
    tokenAddress: '',
    userTokenInfo: { balance: FIXEDNUMBER_0 },
    minToken: 0,
    tokenLocks: [],
    userTokenLocks: [],
    id: 0,
    tokenFeePercent: FIXEDNUMBER_0,
    selectedTokenLock: null,
    allTokenLocks: [],
  },
  actions: {
    async fetchUserTokenInfo({ commit }, { lockHandler, tokenAddress }) {
      const selectedTokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
      commit('changeState', { selectedTokenContract, tokenAddress })
      const tokenFee = await lockHandler.getTokenFee()
      const tokenFeePercent = fixedNumberHelper.from(tokenFee).divUnsafe(fixedNumberHelper.from(100))
      const { name, decimals, symbol, balance, totalSupply } = await lockHandler.getTokenInfoByAddress(tokenAddress)
      const minToken = fixedNumberHelper.from(MIN_DEPOSIT_TOKEN)
      commit('changeState', {
        minToken,
        userTokenInfo: {
          decimals,
          name,
          symbol,
          balance,
          tokenAddress,
          totalSupply: fixedNumberHelper.fromWei(totalSupply, decimals),
        },
        tokenFeePercent,
      })
    },

    async fetchAllTokenLocks({ commit, state }, lockHandler) {
      const allTokenLocks = await lockHandler.getTokenLocks(state.tokenAddress, state.userTokenInfo.decimals)
      commit('changeState', { allTokenLocks })
    },

    async fetchUserTokenLocks({ commit, state }, lockHandler) {
      const userTokenLocks = await lockHandler.getUsertokenLocks(state.tokenAddress, state.userTokenInfo.decimals)
      commit('changeState', { userTokenLocks })
    },

    setSelectedTokenLock({ commit }, selectedTokenLock) {
      commit('changeState', { selectedTokenLock })
    },
    setNewLock({ commit, state }, newLock) {
      const tokenLocks = [...state.tokenLocks, { ...newLock, id: state.id++, condition: ADDRESS_0 }]
      commit('changeState', { tokenLocks })
    },
    editLock({ commit, state }, editedlock) {
      const tokenLocks = state.tokenLocks.map((lock) => {
        if (lock.id === editedlock.id) return editedlock
        return lock
      })

      commit('changeState', { tokenLocks })
    },
    resetTokenLocks({ commit }) {
      commit('changeState', { tokenLocks: [] })
    },
    removeSelectedLock({ commit, state }, index) {
      const tokenLocks = state.tokenLocks.filter((item, idx) => idx !== index)
      commit('changeState', { tokenLocks })
    },
    doubleSelectedLock({ commit, state }, item) {
      const tokenLocks = [...state.tokenLocks, { ...item, id: state.id++ }]
      commit('changeState', { tokenLocks })
    },
  },
  mutations: {},
  getters: {
    totalDebit(state) {
      return state.tokenLocks.reduce((a, b) => {
        if (a.isZero()) return b.amount
        else return fixedNumberHelper.addUnsafe(a, b.amount)
      }, FIXEDNUMBER_0)
    },
    maxDebit(state, getters) {
      return fixedNumberHelper.mulUnsafe(state.userTokenInfo.balance, getters.remainPercent)
    },
    remainPercent(state) {
      return fixedNumberHelper.divUnsafe(
        fixedNumberHelper.subUnsafe(FIXEDNUMBER_100, state.tokenFeePercent),
        FIXEDNUMBER_100
      )
    },
    totalLockedToken(state) {
      const arr = state.allTokenLocks.filter((lock) => Date.now() / 1000 < lock.endEmission)
      return arr.reduce((a, b) => {
        const currentValue = fixedNumberHelper.subUnsafe(b.tokensDeposited, b.tokensWithdrawn)
        if (a.isZero()) return currentValue
        else return fixedNumberHelper.addUnsafe(a, currentValue)
      }, FIXEDNUMBER_0)
    },
  },
}
