import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import settingsSvg from '../assets/icons/settings.svg'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'fa',
  icons: {
    iconfont: 'fa',
    values: {
      // settings: settingsSvg
    },
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#F7CE2A',
          lighten1: '#F8E4A7',
          lighten2: '#FEF8E7',
        },
        success: {
          base: '#5FCD5B',
          lighten1: '#dff5de',
        },
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#F44336',
        info: '#2196F3',
        warning: '#F7CE2A',
      },
      dark: {
        primary: {
          base: '#F7CE2A',
          lighten1: '#F8E4A7',
          lighten2: '#FEF8E7',
        },
        success: {
          base: '#5FCD5B',
          lighten1: '#dff5de',
          lighten2: '#46be8a',
        },
        error: {
          base: '#F44336',
          lighten1: '#ff5c59',
        },
        secondary: {
          lighten1: '#121212',
          lighten2: '#202020',
          lighten3: '#2A2A2A',
          lighten4: '#252525',
        },
      },
    },
  },
})
