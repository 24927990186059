import fixedNumberHelper from '@/helpers/fixedNumberHelper'
import { FIXEDNUMBER_0 } from '@/constants/index'

export default {
  namespaced: true,
  state: {
    tokenLocks: [],
    lockFilter: { lock: true, unlock: false, withdrawn: false },
    tokenAddress: '',
    userTokenInfo: { balance: FIXEDNUMBER_0 },
    tokenLock: {},
    tokenFeePercent: FIXEDNUMBER_0,
  },
  actions: {
    async fetchTokenLocks({ commit }, { tokenAddress, lockHandler }) {
      const { name, decimals, symbol, balance } = await lockHandler.getTokenInfoByAddress(tokenAddress)
      const tokenFee = await lockHandler.getTokenFee()
      const tokenFeePercent = fixedNumberHelper.from(tokenFee).divUnsafe(fixedNumberHelper.from(100))
      const tokenLocks = await lockHandler.getUsertokenLocks(tokenAddress, decimals)

      commit('changeState', {
        userTokenInfo: { decimals, name, symbol, balance, tokenAddress },
        tokenFeePercent,
        tokenLocks,
        tokenAddress,
      })
    },
    setNewLock({ commit, state }, newLock) {
      const tokenLocks = [...state.tokenLocks, newLock]
      commit('changeState', { tokenLocks })
    },
    removeSelectedLock({ commit, state }, index) {
      const tokenLocks = state.tokenLocks.filter((item, idx) => idx !== index)
      commit('changeState', { tokenLocks })
    },
    doubleSelectedLock({ commit, state }, item) {
      const tokenLocks = [...state.tokenLocks, item]
      commit('changeState', { tokenLocks })
    },
    setLockFilter({ commit }, lockFilter) {
      commit('changeState', { lockFilter })
    },
  },
  mutations: {},
  getters: {
    filteredTokenLocks(state) {
      const arr1 = state.lockFilter.lock
        ? state.tokenLocks
            .filter((lock) => Date.now() / 1000 < lock.endEmission)
            .map((item) => {
              item.type = 0
              return item
            })
        : []
      const arr2 = state.lockFilter.unlock
        ? state.tokenLocks
            .filter(
              (lock) =>
                Date.now() / 1000 >= lock.endEmission &&
                fixedNumberHelper.diff(lock.tokensDeposited, lock.tokensWithdrawn)
            )
            .map((item) => {
              item.type = 1
              return item
            })
        : []
      const arr3 = state.lockFilter.withdrawn
        ? state.tokenLocks
            .filter((lock) => !fixedNumberHelper.diff(lock.tokensDeposited, lock.tokensWithdrawn))
            .map((item) => {
              item.type = 2
              return item
            })
        : []
      return [...arr1, ...arr2, ...arr3]
    },
  },
}
