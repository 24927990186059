import moment from 'moment'
import Vue from 'vue'
import { FixedNumber } from '@ethersproject/bignumber'
import { round } from 'lodash'
export const vueFilterRegister = () => {
  Vue.filter('hhmm', (timestamp) => (timestamp ? moment.unix(timestamp).format('HH:mm') : '--:--'))
  Vue.filter('yyyymmdd', (timestamp) => (timestamp ? moment.unix(timestamp).format('YYYY/MM/DD') : ''))
  Vue.filter('ddmmyyyy', (timestamp) => (timestamp ? moment.unix(timestamp).format('DD/MM/YYYY') : ''))
  Vue.filter('fullTime', (timestamp) =>
    timestamp ? moment.unix(timestamp).format('YYYY/MM/DD') + ' at ' + moment.unix(timestamp).format('HH:mm') : ''
  )
  Vue.filter('shortAccount', (account) =>
    !account ? '' : account.substr(0, 5) + '...' + account.substr(account.length - 3)
  )
  Vue.filter('normalizeTimeDuration', (endEmission) => {
    const duration = endEmission * 1000 - Date.now()
    const arr = ['year', 'month', 'week', 'minute', 'second']
    for (const unitTime of arr) {
      const normalizeDuration = Math.trunc(moment.duration(duration).as(unitTime))
      if (normalizeDuration != 0) {
        return moment.duration(normalizeDuration, unitTime).humanize(true)
      }
    }
    return 'a few seconds ago'
  })
  Vue.filter('round', (number, precision = 5) => {
    if (typeof number === 'number') return number ? round(number, precision) : '0'
    if (number instanceof FixedNumber) {
      const rouned = number.round(precision).toString()
      return rouned.endsWith('.0') ? rouned.replace('.0', '') : rouned
    }
    return number
  })
  Vue.filter('formatNumber', (number, maximumFractionDigits = 5, minimumFractionDigits = 2) => {
    const nf = new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
      minimumFractionDigits,
    })
    return nf.format(number)
  })
}
