<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      lockHandler: null,
    }
  },
  computed: {
    ...mapState('auth', ['account']),
  },
  methods: {
    ...mapActions('auth', ['init']),
  },
  async created() {
    await this.init()
  },
}
</script>
<style lang="scss">
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.theme--light {
  .v-app-bar.v-toolbar.v-sheet .v-dialog {
    background-color: #fff !important;
  }
  .v-btn {
    text-transform: none !important;
    &__content {
      color: black !important;
    }
    &--active:before {
      opacity: 1 !important;
    }
    &--outlined {
      background-color: white;
    }
  }
}
.theme--dark {
  .v-data-table tbody tr:nth-of-type(even) {
    background-color: var(--v-secondary-lighten2);
  }
  .v-data-table tbody tr:nth-of-type(odd) {
    background-color: var(--v-secondary-lighten3);
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-app-bar.v-toolbar.v-sheet .v-dialog {
    background-color: var(--v-secondary-lighten2);
  }
  .v-toolbar__content {
    border-bottom: 1px solid #5a5a5a !important;
    padding: 0 !important;
  }
  .v-btn:not(.v-btn--outlined).primary {
    color: black;
  }
  .v-dialog .v-sheet {
    background-color: var(--v-secondary-lighten3) !important;
  }
  .v-sheet .v-sheet {
    background-color: var(--v-secondary-lighten3) !important;
  }
  .v-card .v-card {
    background-color: var(--v-secondary-lighten4) !important;
  }
  .v-btn {
    text-transform: none !important;
    &--active {
      opacity: 1 !important;
      background-color: var(--v-primary-base);
      .v-btn__content {
        color: black !important;
      }
    }
    &--outlined {
      border: thin solid #686868;
      color: white !important;
    }
  }
  .text-medium {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .text-lg {
    font-size: 30px !important;
    font-weight: bold !important;
  }
  //bulk-send
  .v-stepper__step .primary {
    color: black !important;
  }
  .v-stepper__label {
    color: white !important;
  }
  .v-stepper {
    background-color: transparent !important;
  }
}

.v-application {
  a {
    text-decoration: none;
    color: inherit !important;
  }
}
.v-dialog {
  border-radius: 24px !important;
  border: thin solid #686868;
  background-color: var(--v-secondary-lighten2);
  color: white !important;
}
.development-mode {
  border-radius: 4px !important;
}
</style>
