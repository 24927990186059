import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import PluginHelper from '@/helpers/PluginHelper'
import utils from '@/plugins/utils'
import loading from '@/plugins/loading'
import alert from '@/plugins/alert'
import { inputRules } from '@/plugins/rule'
import { vueFilterRegister } from '@/plugins/filters'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import VueCodemirror from 'vue-codemirror'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.use(VueCodemirror)
Vue.use(
  PluginHelper.create({
    $utils: utils,
    $alert: alert,
    $rules: inputRules,
    $loading: loading,
  })
)
vueFilterRegister()
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
