import { FIXEDNUMBER_0 } from '@/constants/index'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import priceHelper from '@/helpers/priceHelper.js'
import alert from '@/plugins/alert'

const Web3 = require('web3')
export const web3 = new Web3(window.ethereum)
export const defaultTokenSymbol = 'BNB'

export const MethodName = {
  MULTI_SEND_ETH_WITH_SAME_VALUE: 1,
  MULTI_SEND_ETH_WITH_DIFFERENT_VALUE: 2,
  MULTI_SEND_COIN_WITH_SAME_VALUE: 3,
  MULTI_SEND_COIN_WITH_DIFFERENT_VALUE: 4,
}

// eslint-disable-next-line no-unused-vars
async function sendRequest(fx, from, value = '0', callback) {
  return await new Promise((resolve, reject) => {
    fx.send({ from, value })
      .on('transactionHash', (hash) => {
        resolve(hash)
      })
      .on('receipt', () => {
        callback({ completed: true })
      })
      .on('error', (error) => {
        callback({ completed: false })
        reject(error)
      })
  })
}

async function sendNormalRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve())
      .on('error', (error) => reject(error))
  })
}

export default class MultiSendHandler {
  multiSendContract
  contractAddress
  constructor(account, mainnet = true) {
    this.account = account
    this.contractAddress = mainnet
      ? process.env.VUE_APP_MULTI_SEND_CONTRACT_ADDRESS_MAIN_NET
      : process.env.VUE_APP_MULTI_SEND_CONTRACT_ADDRESS_TEST_NET
    this.multiSendContract = new web3.eth.Contract(require('./MultiSendContract.abi.json'), this.contractAddress)
  }

  async approvedContract(selectedToken) {
    try {
      const selectedTokenContract = new web3.eth.Contract(
        require('@/helpers/erc20.abi.json'),
        selectedToken.tokenAddress
      )
      const allowance = await selectedTokenContract.methods.allowance(this.account, this.contractAddress).call()
      return !!+web3.utils.fromWei(allowance)
    } catch (e) {
      console.error(e)
      alert.error(e.message)
      return false
    }
  }

  async approveContract(selectedToken) {
    const selectedTokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), selectedToken.tokenAddress)
    const f = selectedTokenContract.methods.approve(this.contractAddress, web3.utils.toWei(`${2 ** 64 - 1}`))
    try {
      await sendNormalRequest(f, this.account)
      alert.success('approve successed!')
    } catch (e) {
      alert.error('approve failed')
      alert.error(e.message)
    }
  }

  async txFee() {
    return fixedNumberHelper.fromWei(await this.multiSendContract.methods.txFee().call())
  }

  async isVIP() {
    return await this.multiSendContract.methods.isVIP(this.account).call()
  }

  normalizeValues(values, decimals) {
    return values.map((item) => fixedNumberHelper.toWei(item, decimals))
  }

  getMethod(addressArr, values, selectedToken = null, methodName) {
    const valuesInWei = this.normalizeValues(values, (selectedToken && selectedToken.decimals) || 18)
    switch (methodName) {
      case MethodName.MULTI_SEND_COIN_WITH_SAME_VALUE:
        return this.multiSendContract.methods.mutiSendCoinWithSameValue(
          selectedToken.tokenAddress,
          addressArr,
          valuesInWei[0]
        )
      case MethodName.MULTI_SEND_COIN_WITH_DIFFERENT_VALUE:
        return this.multiSendContract.methods.mutiSendCoinWithDifferentValue(
          selectedToken.tokenAddress,
          addressArr,
          valuesInWei
        )
      case MethodName.MULTI_SEND_ETH_WITH_SAME_VALUE:
        return this.multiSendContract.methods.mutiSendETHWithSameValue(addressArr, valuesInWei[0])
      case MethodName.MULTI_SEND_ETH_WITH_DIFFERENT_VALUE:
        return this.multiSendContract.methods.mutiSendETHWithDifferentValue(addressArr, valuesInWei)
    }
    return null
  }

  async getEstimateGas(res) {
    const estimateGasInWei = fixedNumberHelper
      .from(res)
      .mulUnsafe(fixedNumberHelper.from(web3.utils.toWei('1', 'gwei')))
    const feeInEth = fixedNumberHelper.fromWei(estimateGasInWei._value)
    const ethPerBnbRatio = await priceHelper.ethPerBnbRatio()
    return fixedNumberHelper.from(feeInEth).mulUnsafe(fixedNumberHelper.from(ethPerBnbRatio))
  }

  //multiSend with same ETH
  async mutiSendETHWithSameValue(addressArr, value, callback, fee = 0) {
    const sentEther = value
      .mulUnsafe(fixedNumberHelper.from(addressArr.length || 0))
      .addUnsafe(fixedNumberHelper.from(fee))
    const f = this.getMethod(addressArr, [value], null, MethodName.MULTI_SEND_ETH_WITH_SAME_VALUE)
    return sendRequest(f, this.account, fixedNumberHelper.toWei(sentEther), callback)
  }

  async estimateGasMutiSendETHWithSameValue(addressArr, value, fee = 0) {
    const sentEther = value
      .mulUnsafe(fixedNumberHelper.from(addressArr.length || 0))
      .addUnsafe(fixedNumberHelper.from(fee))
    const f = this.getMethod(addressArr, [value], null, MethodName.MULTI_SEND_ETH_WITH_SAME_VALUE)
    const res = await f.estimateGas({ from: this.account, value: fixedNumberHelper.toWei(sentEther) })
    return await this.getEstimateGas(res)
  }

  //multiSend with different ETH
  async mutiSendETHWithDifferentValue(addressArr, values, callback, fee = 0) {
    const sumValues = values.reduce((a, b) => {
      return fixedNumberHelper.addUnsafe(a, b)
    }, FIXEDNUMBER_0)
    const sentEther = sumValues.addUnsafe(fixedNumberHelper.from(fee))
    const f = this.getMethod(addressArr, values, null, MethodName.MULTI_SEND_ETH_WITH_DIFFERENT_VALUE)
    return sendRequest(f, this.account, fixedNumberHelper.toWei(sentEther), callback)
  }

  async estimateGasMutiSendETHWithDifferentValue(addressArr, values, fee = 0) {
    const sumValues = values.reduce((a, b) => {
      return fixedNumberHelper.addUnsafe(a, b)
    })
    const sentEther = sumValues.addUnsafe(fixedNumberHelper.from(fee))
    const f = this.getMethod(addressArr, values, null, MethodName.MULTI_SEND_ETH_WITH_DIFFERENT_VALUE)
    const res = await f.estimateGas({ from: this.account, value: fixedNumberHelper.toWei(sentEther) })
    return await this.getEstimateGas(res)
  }
  //multiSend with same Coin
  async mutiSendCoinWithSameValue(addressArr, value, selectedToken, callback, fee = 0) {
    const f = this.getMethod(addressArr, [value], selectedToken, MethodName.MULTI_SEND_COIN_WITH_SAME_VALUE)
    return sendRequest(f, this.account, fixedNumberHelper.toWei(fixedNumberHelper.from(fee)), callback)
  }

  async estimateGasMutiSendCoinWithSameValue(addressArr, value, selectedToken, fee = 0) {
    const f = this.getMethod(addressArr, [value], selectedToken, MethodName.MULTI_SEND_COIN_WITH_SAME_VALUE)
    const res = await f.estimateGas({ from: this.account, value: fixedNumberHelper.toWei(fixedNumberHelper.from(fee)) })
    return await this.getEstimateGas(res)
  }

  //multiSend with different Coin
  async mutiSendCoinWithDifferentValue(addressArr, values, selectedToken, callback, fee = 0) {
    const f = this.getMethod(addressArr, values, selectedToken, MethodName.MULTI_SEND_COIN_WITH_DIFFERENT_VALUE)
    return sendRequest(f, this.account, fixedNumberHelper.toWei(fixedNumberHelper.from(fee)), callback)
  }

  async estimateGasMutiSendCoinWithDifferentValue(addressArr, values, selectedToken, fee = 0) {
    const f = this.getMethod(addressArr, values, selectedToken, MethodName.MULTI_SEND_COIN_WITH_DIFFERENT_VALUE)
    const res = await f.estimateGas({ from: this.account, value: fixedNumberHelper.toWei(fixedNumberHelper.from(fee)) })
    return await this.getEstimateGas(res)
  }

  async getTokenInfoByAddress(tokenAddress) {
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
    const [name, decimals, symbol, balance] = await Promise.all([
      tokenContract.methods.name().call(),
      tokenContract.methods.decimals().call(),
      tokenContract.methods.symbol().call(),
      tokenContract.methods.balanceOf(this.account).call(),
    ])
    const balancefromWei = fixedNumberHelper.fromWei(balance, decimals)
    return {
      decimals,
      name,
      symbol,
      balance: balancefromWei,
      tokenAddress: tokenAddress,
    }
  }
}
