import { FIXEDNUMBER_0 } from '@/constants/index'
import { orderBy } from 'lodash'
import alert from '@/plugins/alert'
import moment from 'moment'
export default {
  namespaced: true,
  state: {
    claims: [],
    addClaimLoading: false,
    completed: false,
    remaining: false,
    isIncreaseCreatedDate: false,
  },
  actions: {
    async fetchData({ commit }, multiClaimHandler) {
      let res = await multiClaimHandler.getClaimByOwners()
      if (res) {
        const claims = JSON.parse(JSON.stringify(Object.values(res)))
        commit('changeState', { claims })
      }
    },
    // eslint-disable-next-line no-unused-vars
    async addClaim({ commit }, { multiClaimHandler, claimInfo }) {
      let res = false
      try {
        commit('changeState', { addClaimLoading: true })
        const isVip = await multiClaimHandler.isVIP()
        const fee = isVip ? FIXEDNUMBER_0 : await multiClaimHandler.txFee()
        res = await multiClaimHandler.addClaim(claimInfo, fee._value)
      } catch (e) {
        console.error('Add pool error:', e)
        alert.error(e.message)
      } finally {
        if (res) alert.success('Add pool success')
        commit('changeState', { addClaimLoading: false })
      }
      return res
    },
    resetClaimInfo({ commit }) {
      commit('changeState', { addClaimLoading: false })
    },

    toggleCompletedFilter({ commit, state }) {
      commit('changeState', { completed: !state.completed })
    },

    toggleRemainingFilter({ commit, state }) {
      commit('changeState', { remaining: !state.remaining })
    },
    toggleOrderByCreatedDate({ commit, state }) {
      commit('changeState', { isIncreaseCreatedDate: !state.isIncreaseCreatedDate })
    },
  },
  mutations: {},
  getters: {
    validClaims: (state) => {
      let validClaims = []
      if ((state.completed && state.remaining) || (!state.completed && !state.remaining)) {
        validClaims = state.claims
      } else if (state.completed) {
        const now = moment()
        validClaims = state.claims.filter((item) => {
          item.activeDate && moment(item.activeDate * 1000).isBefore(now) && item.claimedNumber === item.winnerNumber
        })
      } else {
        const now = moment()
        validClaims = state.claims.filter((item) => {
          return (
            (item.activeDate && moment(item.activeDate * 1000).isAfter(now)) || item.claimedNumber !== item.winnerNumber
          )
        })
      }
      return orderBy(validClaims, ['createdDate'], state.isIncreaseCreatedDate ? 'asc' : 'desc')
    },
  },
}
