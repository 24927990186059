import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import priceHelper from '@/helpers/priceHelper.js'
import alert from '@/plugins/alert'
import { MAX_ACCOUNT_PER_TRANS } from '@/constants/index'
import { uniq, keyBy } from 'lodash'

const Web3 = require('web3')
export const web3 = new Web3(window.ethereum)

async function sendRequestWithCallBack(fx, from, callback = null) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('transactionHash', (hash) => {
        if (callback) resolve(hash)
      })
      .on('receipt', () => {
        if (callback) {
          callback({ completed: true })
        }
        resolve(true)
      })
      .on('error', (error) => {
        if (callback) {
          callback({ completed: false })
        }
        reject(error)
      })
  })
}

async function sendRequest(fx, from, value = '0') {
  return await new Promise((resolve, reject) => {
    fx.send({ from, value })
      .on('receipt', () => resolve(true))
      .on('error', (error) => reject(error))
  })
}

export default class MultiClaimHandler {
  multiClaimContract
  contractAddress
  constructor(account, mainnet = true) {
    this.account = account
    this.contractAddress = mainnet
      ? process.env.VUE_APP_MULTI_CLAIM_CONTRACT_ADDRESS_MAIN_NET
      : process.env.VUE_APP_MULTI_CLAIM_CONTRACT_ADDRESS_TEST_NET
    this.multiClaimContract = new web3.eth.Contract(require('./MultiClaimContract.abi.json'), this.contractAddress)
  }

  async approvedContract(token) {
    try {
      const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
      const allowance = await tokenContract.methods.allowance(this.account, this.contractAddress).call()
      return !!+web3.utils.fromWei(allowance)
    } catch (e) {
      console.error(e)
      alert.error(e.message)
      return false
    }
  }

  async approveContract(token) {
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), token.tokenAddress)
    const f = tokenContract.methods.approve(this.contractAddress, web3.utils.toWei(`${2 ** 64 - 1}`))
    try {
      await sendRequest(f, this.account)
      alert.success('approve successed!')
    } catch (e) {
      alert.error('approve failed')
      alert.error(e.message)
    }
  }

  async getClaimByOwners() {
    //get claims data
    const claimByOwnerlength = await this.multiClaimContract.methods.getClaimByOwnersLength(this.account).call()
    const claimIds = await this.multiClaimContract.methods
      .getClaimListByOwner(this.account, 0, claimByOwnerlength)
      .call()
    const claimInfoPromises = []
    const claimProcessPromises = []
    for (let i = 0; i < claimIds.length; i++) {
      claimInfoPromises.push(this.multiClaimContract.methods.CLAIMS(claimIds[i]).call())
      claimProcessPromises.push(this.multiClaimContract.methods.getClaimProcessInfoById(claimIds[i]).call())
    }
    //get list of winner info as: claimid, winners, amounts, claimed
    //get list of claim info as: claimId, tokenAddress,...
    const claimInfoRes = await Promise.all(claimInfoPromises)
    const claimProcessRes = await Promise.all(claimProcessPromises)
    let claimInfos = []
    let tokenAddresses = []
    claimInfoRes.map((item) => {
      const {
        0: id,
        1: amount,
        2: claimedAmount,
        3: activeDate,
        4: createdDate,
        5: description,
        6: tokenAddress,
        7: name,
        8: owner,
        9: paused,
        10: closed,
      } = item
      if (id && !closed) {
        tokenAddresses.push(tokenAddress)
        claimInfos[id] = {
          id,
          amount,
          claimedAmount,
          activeDate,
          description,
          tokenAddress,
          createdDate,
          name,
          owner,
          paused,
          closed,
        }
      }
      return
    })
    claimProcessRes.map((item) => {
      const { 0: id, 1: claimedNumber, 2: winnerNumber } = item
      if (id && claimInfos[id]) claimInfos[id] = { ...claimInfos[id], claimedNumber, winnerNumber }
      return
    })

    tokenAddresses = uniq(tokenAddresses)
    const tokens = await this.getListTokens(tokenAddresses)
    const tokensByKey = keyBy(tokens, 'tokenAddress')
    const claimInfoWithTokens = claimInfos.map((item) => {
      if (item.tokenAddress) {
        item.token = tokensByKey[item.tokenAddress]
        item.amount = fixedNumberHelper.fromWei(item.amount || 0, (item.token && item.token.decimals) || 18)._value
      }
      return item
    })

    return claimInfoWithTokens
  }

  async getClaimInfo(id) {
    const claimInfo = await this.multiClaimContract.methods.CLAIMS(id).call()
    const token = await this.getTokenInfoByAddress(claimInfo.tokenAddress)
    const decimals = token ? token.decimals : 18
    const winnerInfo = await this.getWinnerInfo(id, decimals)
    return {
      claimInfo: { ...claimInfo, amount: fixedNumberHelper.fromWei(claimInfo.amount, decimals), token },
      winnerInfo,
    }
  }

  async getWinnerInfo(claimId, decimals) {
    const winnerListLength = await this.multiClaimContract.methods.getWinnerListLengthByClaimId(claimId).call()
    const promises = []
    for (let i = 0; i < Math.ceil(winnerListLength / MAX_ACCOUNT_PER_TRANS); i++) {
      promises.push(
        this.multiClaimContract.methods.getWinnerInfo(claimId, i * MAX_ACCOUNT_PER_TRANS, MAX_ACCOUNT_PER_TRANS).call()
      )
    }
    const res = await Promise.all(promises)
    let winnerList = [],
      claimedList = [],
      amountList = []
    res.map((item) => {
      if (item) {
        const { 1: winnerAddresses, 2: winnerAmounts, 3: claimedWinners } = item
        winnerList = winnerList.concat(winnerAddresses)
        claimedList = claimedList.concat(claimedWinners)
        amountList = amountList.concat(winnerAmounts)
      }
      return
    })
    if (winnerList.length !== claimedList.length || claimedList.length != amountList.length)
      throw 'get winner info fail'
    const amountListFromWei = amountList.map((item) => {
      return fixedNumberHelper.fromWei(item, decimals)._value
    })
    return { winnerList, claimedList, amountList: amountListFromWei }
  }

  async getListTokens(listTokenAddress) {
    let promises = []
    for (let i = 0; i < listTokenAddress.length; i++) {
      promises.push(this.getTokenInfoByAddress(listTokenAddress[i]))
    }
    return await Promise.all(promises)
  }

  async addClaim(claimParam, fee = 0) {
    const f = this.multiClaimContract.methods.addClaim(claimParam)
    return sendRequest(f, this.account, fixedNumberHelper.toWei(fixedNumberHelper.from(fee)))
  }

  normalizeValues(values, decimals) {
    return values.map((item) => fixedNumberHelper.toWei(item, decimals))
  }

  async addWinnerList(claimId, to, values, decimals = 18, callback = null) {
    const valuesInWei = this.normalizeValues(values, decimals)
    const f = this.multiClaimContract.methods.addWinnerList(claimId, to, valuesInWei)
    return sendRequestWithCallBack(f, this.account, callback)
  }

  async claimTokens(claimId, callback = null) {
    const f = this.multiClaimContract.methods.claimTokens(claimId)
    return sendRequestWithCallBack(f, this.account, callback)
  }

  async changeActiveDate(id, activeDate) {
    const f = this.multiClaimContract.methods.changeActiveDate(id, activeDate)
    return sendRequest(f, this.account)
  }

  async closeClaim(id) {
    const f = this.multiClaimContract.methods.closeClaim(id)
    return sendRequest(f, this.account)
  }

  async pause(id) {
    const f = this.multiClaimContract.methods.pause(id)
    return sendRequest(f, this.account)
  }

  async unpause(id) {
    const f = this.multiClaimContract.methods.unpause(id)
    return sendRequest(f, this.account)
  }

  async changeDescription(id, description) {
    const f = this.multiClaimContract.methods.changeDescription(id, description)
    return sendRequest(f, this.account)
  }

  async changeName(id, name) {
    const f = this.multiClaimContract.methods.changeName(id, name)
    return sendRequest(f, this.account)
  }

  async estimateGasAddWinnerList(id, to, values, decimals = 18) {
    const valuesInWei = this.normalizeValues(values, decimals)
    const f = this.multiClaimContract.methods.addWinnerList(id, to, valuesInWei)
    const res = await f.estimateGas({ from: this.account })
    return await this.getEstimateGas(res)
  }

  async txFee() {
    return fixedNumberHelper.fromWei(await this.multiClaimContract.methods.txFee().call())
  }

  async isVIP() {
    return await this.multiClaimContract.methods.isVIP(this.account).call()
  }

  async getEstimateGas(res) {
    const estimateGasInWei = fixedNumberHelper
      .from(res)
      .mulUnsafe(fixedNumberHelper.from(web3.utils.toWei('1', 'gwei')))
    const feeInEth = fixedNumberHelper.fromWei(estimateGasInWei._value)
    const ethPerBnbRatio = await priceHelper.ethPerBnbRatio()
    return fixedNumberHelper.from(feeInEth).mulUnsafe(fixedNumberHelper.from(ethPerBnbRatio))
  }

  async getTokenInfoByAddress(tokenAddress) {
    const tokenContract = new web3.eth.Contract(require('@/helpers/erc20.abi.json'), tokenAddress)
    const [name, decimals, symbol, balance] = await Promise.all([
      tokenContract.methods.name().call(),
      tokenContract.methods.decimals().call(),
      tokenContract.methods.symbol().call(),
      tokenContract.methods.balanceOf(this.account).call(),
    ])
    const balancefromWei = fixedNumberHelper.fromWei(balance, decimals)
    return {
      decimals,
      name,
      symbol,
      balance: balancefromWei,
      tokenAddress: tokenAddress,
    }
  }
}
