import { web3 } from '@/helpers/LockHandler.js'
export const inputRules = {
  required: (v) => (!!v && (typeof v !== 'string' || !!v.trim())) || v === 0 || 'Required value',
  contractAddress: (v) => {
    if (!v) return true
    return web3.utils.isAddress(v) || 'Invalid address'
  },
  date: (v) => {
    return (!!v && Date.parse(v) / 1000 <= 1e10 && Date.parse(v) >= 0) || 'Invalid date'
  },
  positiveNumber: (v) => !v || (!!v && new RegExp(/^\d+(\.\d*)?$/).test(v)) || 'Invalid value',
}
